import * as mixpanel from 'mixpanel-browser';
import { get } from 'lodash';
import moment from 'moment';

export class MixpanelService {
  boot(userData, bootFromLogin = false) {
    let user;
    if (bootFromLogin) {
      user = get(userData, 'user', '');
    } else {
      const currentUser = localStorage.getItem('currentUser');
      try {
        userData = JSON.parse(currentUser);
        user = get(userData, 'user', '');
      } catch (e) {}
    }
    console.log(userData);

    console.log(user);

    //TODO: remove hardcode
    //TODO: remove debug at production
    mixpanel.init('96a22e2796e1f530429aa62a71bd996a', { debug: true });
    if (user && user.id) {
      const data = {
        $distinct_id: user.id,
        $name: user.fullName,
        'Company Id': user.companyId,
        'Company Name': user.companyName,
        $email: user.email,
      };
      console.log(data);
      mixpanel.identify(user.userId);
      mixpanel.register_once({
        'First Login Date': moment(),
      });
      mixpanel.register(data);
      mixpanel.people.set(data);
    }
    mixpanel.track('First Load', { ...userData });
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }

  logout() {
    mixpanel.reset();
  }
}
