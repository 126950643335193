import { Tabs as BaseTabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';
import React from 'react';
const { TabPane } = BaseTabs;
const Tabs = (props: TabsProps) => (
  <BaseTabs
    {...props}
    className={'deo-tab-basic ' + (props.className || '')}
  >

  </BaseTabs>
)

export {
  Tabs, TabPane
}
