import { LocalStorageService } from '@clodeo/libs/core/local-storage/local-storage.service';
import { AppService } from 'apps/portal/src/app/app.service';
import _ from 'lodash';

const localStorageService = new LocalStorageService();
export class MenuRfService {
  static get menus(): IMenuRf[] {
    const isOwner = localStorageService.getItem('currentUser')?.user?.isOwner;

    return _.compact([
      {
        id: 'dashboard',
        icon: 'i-CirclesFour',
        label: 'ui.menu.dashboard',
        // permissions: ['dashboard.general.view'],
        to: '/features/dashboard',
      },
      {
        id: 'order',
        icon: 'i-ListDashes',
        label: 'ui.menu.order',
        // permissions: ['order.general.view'],
        to: '/features/order',
      },
      {
        id: 'contact',
        icon: 'i-PhoneBook',
        label: 'ui.menu.contact',
        // permissions: ['contact.general.view'],
        to: '/features/contact',
      },
      isOwner && {
        id: 'user',
        icon: 'i-UserCircle',
        label: 'ui.menu.user',
        // permissions: ['user.general.view'],
        to: '/features/user/list',
        subs: [
          {
            id: 'user-list',
            label: 'ui.menu.user',
            to: '/features/user/list',
          },
          {
            id: 'branch-list',
            label: 'ui.menu.subMenu.branch',
            to: '/features/user/branch',
          },
          {
            id: 'department-list',
            label: 'ui.menu.subMenu.department',
            to: '/features/user/department',
          },
        ],
      },
      // {
      //   id: 'warehouse',
      //   icon: 'i-Package',
      //   label: 'Warehouse',
      //   // permissions: ['warehouse.general.view'],
      //   to: '/features/warehouse',
      // },
      {
        id: 'setting',
        icon: 'i-SlidersHorizontal',
        label: 'ui.menu.setting',
        // permissions: ['setting.general.view'],
        to: '/features/setting/profile',
        subs: isOwner && [
          {
            id: 'profile',
            label: 'ui.menu.subMenu.profile',
            to: '/features/setting/profile',
          },
          {
            id: 'api-key',
            label: 'ui.menu.subMenu.api_key_authorization',
            to: '/features/setting/api-key',
          },
          {
            id: 'company',
            label: 'ui.menu.subMenu.company',
            to: '/features/setting/company',
          },
        ],
      },
    ]);
  }
}
