import { environment } from '../../../../environments/environment';
import { tap, switchMap, catchError } from 'rxjs/operators';
import { get } from 'lodash';
import { UserVariableRestService } from './user-variable-rest.service';

import { AuthenticationService } from '../authentication.service';
declare interface IUserVariable  {
  key: string;
  value: any;
}

const authenticationService: AuthenticationService = new AuthenticationService();
import { isArray } from 'lodash';
let tempData: IUserVariable[] = [];
export class UserVariableService {
  userVariableRestService: UserVariableRestService;
  constructor() {
    this.userVariableRestService = new UserVariableRestService(
      environment.ENDPOINTS.API_SHIPDEO_MAIN,
      authenticationService.axiosInterceptors
    );
  }

  set data(newData: IUserVariable[]) {
    tempData = newData;
  }

  get data() {
    return tempData;
  }

  storeData(payload: IUserVariable) {
    payload.value = JSON.stringify(payload.value);
    return this.userVariableRestService.setData(payload).pipe(
      tap((response) => {
        this.addData(payload);
      })
    );
  }

  loadData() {
    return this.userVariableRestService.getData().pipe(
      tap((response) => {
        this.data = response.data;
      })
    );
  }

  // this function for manual update
  addData(newData: IUserVariable) {
    let iExistData: number;
    this.data.forEach((variable, index) => {
      iExistData = index;
    });
    if (iExistData > -1) {
      this.data[iExistData].value = newData.value;
    } else {
      this.data = [
        ...this.data,
        {
          key: newData.key,
          value: JSON.parse(newData.value),
        },
      ];
    }
  }

  retreiveByKey(key: string): IUserVariable {
    const data = this.data.filter((variable) => variable.key == key);
    const defaultData = {
      key: '',
      value: '',
    };
    if (data.length) {
      let dataValue = get(data, '[0].value');

      if (typeof dataValue !== 'string') {
        dataValue = JSON.stringify(dataValue);
      }
      return {
        key: get(data, '[0].key') || '',
        value: JSON.parse(dataValue),
      };
    } else {
      return defaultData;
    }
  }
}
