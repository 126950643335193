import React, { createContext } from 'react';
import {
  Route,
  Redirect,
  useHistory,
  useLocation,
  RouteProps
} from "react-router-dom";
import { MainLayoutComponent } from '../layout-component/main/main-layout.component';
import { IGuardRoute } from './guard-route';
import { AclService } from '../../core/auth/acl.service';
import { AuthenticationService } from '../../core/auth/authentication.service';
import { MixpanelService } from '@clodeo/libs/core/mixpanel/mixpanel.service';

const authService: AuthenticationService = new AuthenticationService;
const mixpanelService: MixpanelService = new MixpanelService;
const aclService: AclService = new AclService;
const authContext = createContext(authService.user);

function useProvideAuth() {
  return authService.user
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function GuardRoute(props: IGuardRoute) {
  let location = useLocation();
  const router = useHistory();
  const pathName = location.pathname;
  let auth = authService.user;

  if (pathName !== '/register-company' && auth && !auth.user?.hasCompany) {
    router.push('/register-company');
  }

  if ((pathName === '/'  && auth || pathName === '/register-company' && auth) && auth.user?.hasCompany) {
    aclService.redirectAllowedMenu(router);
  } else if (props?.meta?.permissions) {
    const isValidRoles = aclService.can(props.meta.permissions);
    if (!isValidRoles) {
      router.push(`/403`);
    }

  }

  if (props.title) {
    mixpanelService.track(`sidebar-${props.title}`, {action: `User click sidebar-${props.title}`});
  }

  return (
    <Route
      {...props}
      children={null}
      render={({ location }) =>
        auth ? (
          // <MainLayoutComponent title={props.title || ''} pathName={location.pathname}>
          props.children
          // </MainLayoutComponent>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}
