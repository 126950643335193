import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en, id } from './i18n.locales'
import Cookies from 'js-cookie'

const resources = {
    id: {
        translation: id
    },
    en: {
        translation: en
    }
}
i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: Cookies.get('i18nLang') ? Cookies.get('i18nLang') : 'en',
        fallbackLng: Cookies.get('i18nLang') ? Cookies.get('i18nLang') : 'en'
    });

export default i18n;