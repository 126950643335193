import React, { useEffect, useState } from 'react';
import { Menu } from '@clodeo/clodeo-ui/components/navigation/menu/menu.component';
import { NavLink } from 'react-router-dom';
import { Icon } from '@clodeo/clodeo-ui/components/foundations/icon/icon.component';
import { useTranslation } from 'react-i18next';
import './menu-layout.component.scss';
import _ from 'lodash';

export const MenuComponent = (props: MenuRfProps) => {
  const [menus, setMenus] = useState<IMenuRf[]>(null);
  const [compact, setCompact] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setMenus(props.menus);
    props.onCompactChange && props.onCompactChange(compact);
  }, [props.menus, compact]);

  function isActive(to: string) {
    const path = _.split(props?.pathName, '/');
    const current = _.split(to, '/');
    return path[2] === current[2];
  }

  return (
    menus && (
      <Menu
        className={`menu-layout-admin-deo ${compact ? 'compact-view' : ''}`}
        mode="inline"
      >
        <div className="d-flex flex-column justify-content-between h-100">
          <div>
            {menus.map((menu: IMenuRf, i: number) => (
              <div key={i}>
                <NavLink
                  to={menu.to}
                  className={`wrapper-nav-link ${
                    compact ? 'compact-view' : ''
                  }`}
                  isActive={() => isActive(menu.to)}
                  activeClassName="active"
                >
                  <div className="icon-wrapper">
                    <Icon name={menu.icon} />
                  </div>
                  {!compact && (
                    <div className="label pl-2">{t(menu.label)}</div>
                  )}
                </NavLink>
              </div>
            ))}
          </div>
          <div
            className={`wrapper-nav-link compact ${
              compact && 'compact-view active'
            }`}
            onClick={() => setCompact(!compact)}
          >
            <div className="icon-wrapper">
              <Icon name="icon icon-deo-sidebar" />
            </div>
            {!compact && <div className="label">Compact View</div>}
          </div>
        </div>
      </Menu>
    )
  );
};
