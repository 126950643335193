import React from 'react';
import {
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import { IGuestRoute } from './guest-route';
import { AclService } from '../../core/auth/acl.service';
import { AuthenticationService } from '../../core/auth/authentication.service';

const authService: AuthenticationService = new AuthenticationService;
const aclService: AclService = new AclService;

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function GuestRoute(props: IGuestRoute) {
  let location = useLocation();
  const router = useHistory();
  const pathName = location.pathname;
  let auth = authService.user;

  if (auth) {
    aclService.redirectAllowedMenu(router);
  }

  return (
    <Route
      {...props}
      children={null}
      render={({ location }) =>
        props.children
      }
    />
  );
}
