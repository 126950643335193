export const environment = {
  environmentName: 'dev',
  production: false,
  CONTENTS: {
    TITLE: 'Sicepat',
    CLIENT_ID: 'sicepat',
    COMPANY_LOGO: 'https://nx-design-system-web-development.clodeo.com/img/logo/courier/sicepat/new-logo-sicepat.svg',
    SMALL_COMPANY_LOGO: 'https://nx-design-system-web-development.clodeo.com/img/logo/courier/sicepat/small-logo-sicepat.svg',
    WHITE_COMPANY_LOGO: 'https://nx-design-system-web-development.clodeo.com/img/logo/courier/sicepat/white-logo-sicepat.svg',
    APP_COLOR: '#d0021b',
    FAVICON: 'https://sicepat.com/images/icon/favicon.ico',
    COURIERS: ['sicepat'],
    WHITELABELID: '611f52b9d3c0f10462734ad8'
  },
  ENDPOINTS: {
    API_ACCOUNT: 'https://auth-api-development.shipdeo.com',
    API_ADMIN: 'https://admin-api-development.shipdeo.com/v1',
    API_MAIN: 'https://portal-api-development.shipdeo.com',
    API_FILE: 'https://portal-api-development.shipdeo.com/v1/file/upload-image',
    API_SHIPDEO_MAIN: 'https://main-api-development.shipdeo.com/v1',
  },
  KEYS: {
    GOOGLE_MAP: 'AIzaSyBd0AWvcQ8EIsfnIn2c9umbidHjHqebBDA'
  }
}