import React, { Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { GuardRoute } from '../../components/guard-route/guard-route.component';
import { MainLayoutComponent } from '../../components/layout-component/main/main-layout.component';
import { LazyPageComponent } from '@clodeo/clodeo-ui/components/page/lazy/lazy-page.component';

// import { ContactComponent } from './features/main/contact/contact.component';
const DashboardComponent = React.lazy(
  () => import('./dashboard/dashboard.component')
);
const OrderComponent = React.lazy(() => import('./order/order.component'));
const OrderDetailComponent = React.lazy(
  () => import('./order/detail/order-detail.component')
);
const OrderCreateComponent = React.lazy(
  () => import('./order/create/order-create.component')
);
const OrderUpdateComponent = React.lazy(
  () => import('./order/update/order-update.component')
);
const OrderImportComponent = React.lazy(
  () => import('./order/import/order-import.component')
);
const ContactComponent = React.lazy(
  () => import('./contact/contact.component')
);
const ContactImportComponent = React.lazy(
  () => import('./contact/import/contact-import.component')
);
const WarehouseComponent = React.lazy(
  () => import('./warehouse/warehouse.component')
);
const UserListComponent = React.lazy(
  () => import('./user/list/user-list.component')
);
const UserListImportComponent = React.lazy(
  () => import('./user/list/import/user-list-import.component')
);
const UserBranchComponent = React.lazy(
  () => import('./user/branch/user-branch.component')
);
const UserBranchImportComponent = React.lazy(
  () => import('./user/branch/import/user-branch-import.component')
);
const UserDepartmentComponent = React.lazy(
  () => import('./user/department/user-department.component')
);
const UserDepartmentImportComponent = React.lazy(
  () => import('./user/department/import/user-department-import.component')
);
const SettingProfileComponent = React.lazy(
  () => import('./setting/profile/setting-profile.component')
);
const SettingApiKeyComponent = React.lazy(
  () => import('./setting/api-key/setting-api-key.component')
);
const SettingConfigurationCompanyComponent = React.lazy(
  () =>
    import(
      './setting/configuration-company/setting-configuration-company.component'
    )
);
// import { SettingApiKeyComponent } from './features/main/setting/api-key/setting-api-key.component';
// import { SettingProfileComponent } from './features/main/setting/profile/setting-profile.component';
// import { UserBranchComponent } from './features/main/user/branch/user-branch.component';
// import { UserDepartmentComponent } from './features/main/user/department/user-department.component';
// import { UserListComponent } from './features/main/user/list/user-list.component';
// import { WarehouseComponent } from './features/main/warehouse/warehouse.component';

export function MainRouter() {
  let { path, url } = useRouteMatch();
  return (
    <>
      <MainLayoutComponent>
        <Suspense fallback={<LazyPageComponent />}>
          <Switch>
            <GuardRoute title="Dashboard" path={`${url}/dashboard`} exact>
              <DashboardComponent />
            </GuardRoute>
            <GuardRoute title="Order" path={`${url}/order`} exact>
              <OrderComponent />
            </GuardRoute>
            <GuardRoute title="Order Import" path={`${url}/order/import`} exact>
              <OrderImportComponent />
            </GuardRoute>
            <GuardRoute title="Order Create" path={`${url}/order/create`} exact>
              <OrderCreateComponent />
            </GuardRoute>
            <GuardRoute title="Order update" path={`${url}/order/:id/update`} exact>
              <OrderUpdateComponent />
            </GuardRoute>
            <GuardRoute title="Order Detail" path={`${url}/order/:id`} exact>
              <OrderDetailComponent />
            </GuardRoute>
            <GuardRoute title="ui.menu.contact" path={`${url}/contact`} exact>
              <ContactComponent />
            </GuardRoute>
            <GuardRoute
              title="Contact Import"
              path={`${url}/contact/import`}
              exact
            >
              <ContactImportComponent />
            </GuardRoute>
            <GuardRoute title="Warehouse" path={`${url}/warehouse`} exact>
              <WarehouseComponent />
            </GuardRoute>
            <GuardRoute title="User List" path={`${url}/user/list`} exact>
              <UserListComponent />
            </GuardRoute>
            <GuardRoute
              title="User Import"
              path={`${url}/user/list/import`}
              exact
            >
              <UserListImportComponent />
            </GuardRoute>
            <GuardRoute
              title="User Branch List"
              path={`${url}/user/branch`}
              exact
            >
              <UserBranchComponent />
            </GuardRoute>
            <GuardRoute
              title="User Branch Import"
              path={`${url}/user/branch/import`}
              exact
            >
              <UserBranchImportComponent />
            </GuardRoute>
            <GuardRoute
              title="User Department List"
              path={`${url}/user/department`}
              exact
            >
              <UserDepartmentComponent />
            </GuardRoute>
            <GuardRoute
              title="User Department Import"
              path={`${url}/user/department/import`}
              exact
            >
              <UserDepartmentImportComponent />
            </GuardRoute>
            <GuardRoute
              title="Setting Profile"
              path={`${url}/setting/profile`}
              exact
            >
              <SettingProfileComponent />
            </GuardRoute>
            <GuardRoute
              title="Setting API Key"
              path={`${url}/setting/api-key`}
              exact
            >
              <SettingApiKeyComponent />
            </GuardRoute>
            <GuardRoute
              title="Setting Company"
              path={`${url}/setting/company`}
              exact
            >
              <SettingConfigurationCompanyComponent />
            </GuardRoute>
          </Switch>
        </Suspense>
      </MainLayoutComponent>
    </>
  );
}
