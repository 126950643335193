import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { MixpanelService } from '@clodeo/libs/core/mixpanel/mixpanel.service';
import { Provider } from 'react-redux';
import { store } from './app/core/redux/store';
import { App } from './app/app';
import GA4React from 'ga-4-react';
import { environment } from './environments/environment';
import { GeneratorScriptService  } from 'libs/core/src/generatore-script/generator-script.service';

const tagManagerArgs = {
  gtmId: 'G-TCMT2N4ZXG',
};
const ga4React = new GA4React(tagManagerArgs.gtmId);

(async () => {
  const mixpanelService = new MixpanelService();
  mixpanelService.boot(null);
  const generatorScriptService = new GeneratorScriptService();
  await generatorScriptService.bootIntercom(null);
  generatorScriptService.generateScript({ facebook_pixel_key: "323783306473277" }, { fb: {fbqStatus: true} });

  if (environment.production) {
    await ga4React.initialize();
  }

  ReactDOM.render(
    <StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </StrictMode>,
    document.getElementById('root')
  );
})();
