import React from 'react';
import { AppRouter } from './app-router';
import './app.scss';
import '@clodeo/libs/core/translate/i18n';
import { environment } from '../environments/environment';
import Favicon from 'react-favicon';
import DocumentMeta from 'react-document-meta';

import { GeneratorScriptService } from '@clodeo/libs/core/generatore-script/generator-script.service';
import { UserVariableService } from './core/auth/user-variable/user-variable.service';

const userVariableService: UserVariableService = new UserVariableService();
export class App extends React.Component {
  // componentDidMount() {
    // if (!userVariableService.data) {
    //   userVariableService.loadData().subscribe();
    // }
  // }
  render() {
    return (
      <DocumentMeta title={environment.CONTENTS.TITLE}>
        <Favicon url={environment.CONTENTS.FAVICON} />
        <AppRouter />
      </DocumentMeta>
    );
  }
}
 