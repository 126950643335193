import { RequestService } from '../../request.service';
export class TokenRestService {
  requestService = new RequestService();
  request: RequestService;
  config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    }
  };

  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(baseUrl, useInterceptor, withoutInterceptor);
  }

  getToken(payload) {
    return this.request.post<any>('/login', payload);
  }

  getClientCredential(username = '', password = '') {
    return this.request.post<any>(`/validate`, null, {
      params: {
        username,
        password
      }
    });
  }

  verifyStatus(id: string) {
    return this.request.put<any>(`user/active/${id}`, null);
  }

  verifyEmail(payload: any) {
    return this.request.post<any>(`user/verify-email`, payload);
  }

  resetPassword(id, payload: any) {
    return this.request.put<any>(`user/reset-password/${id}`, payload);
  }

  refreshToken(payload: any) {
    return this.request.post<any>(`/login`, payload);
  }
}
