import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { SiderLayoutProps } from './sider-layout';
import { environment } from 'apps/portal/src/environments/environment';

const { Sider } = Layout;

import './sider-layout.component.scss';
export const SiderLayoutComponent = (props: SiderLayoutProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const [, setIsLg] = useState(false);
  const [width, setWidth] = useState<number | string>('auto');
  const [src, setSrc] = useState([]);

  useEffect(() => {
    if (collapsed) {
      // setSrc('/img/logo/clodeo/icon.svg');
      setWidth('0');
    } else {
      // setSrc('/img/logo/clodeo/regular.svg');
      setWidth('auto');
    }
    props.compact
      ? setSrc([environment.CONTENTS.SMALL_COMPANY_LOGO,
          '40%',
        ])
      : setSrc([
          environment.CONTENTS.COMPANY_LOGO,
          '100%',
        ]);
    // setSrc('/img/logo/clodeo/icon.svg')
    props.onCollapseChanges && props.onCollapseChanges(collapsed);
  }, [collapsed, props.compact]);

  return (
    <Sider
      width={width}
      collapsedWidth={'0'}
      collapsed={collapsed}
      className="sider-admin-deo"
      breakpoint="lg"
      onCollapse={setCollapsed}
      onBreakpoint={(broken) => {
        if (broken) {
          setCollapsed(true);
          setIsLg(false);
        } else {
          setCollapsed(false);
          setIsLg(true);
        }
      }}
    >
      {props.logo || (
        <div
          className={
            'no-select-img logo' +
            (props.compact ? ' compact p-3' : ' non-compact-animation p-4')
          }
        >
          <img
            src={src[0]}
            alt="shipdeo portal"
            srcSet=""
            style={{ width: src[1] }}
          />
        </div>
      )}
      {props.menu || ''}
    </Sider>
  );
};
