import { Layout } from 'antd';
import { find, split } from 'lodash';
import React, { useEffect, useState } from 'react';
import { AclService } from '../../../core/auth/acl.service';
import { HeaderLayoutComponent } from '../header/header-layout.component';
import { MenuComponent } from '../menu/menu-layout.component';
import { SiderLayoutComponent } from '../sider/sider-layout.component';
import './main-layout.component.scss';
import { useLocation } from 'react-router-dom';
const { Content } = Layout;
const aclService: AclService = new AclService();

export const MainLayoutComponent = (props: {
  children?: any;
  // pathName: string;
  // title?: string;
}) => {
  const [menus, setMenus] = useState<IMenuRf[]>([]);
  const [collapseSider, setCollapseSider] = useState<boolean>();
  const [parentMenu, setParentMenu] = useState<IMenuRf>();
  const [compact, setCompact] = useState(false);
  const location = useLocation();

  function isSubMenu() {
    return parentMenu && parentMenu.subs && parentMenu.subs.length > 0;
  }

  useEffect(() => {
    if (menus && location.pathname) {
      const parentPath = split(location.pathname, '/');
      const menu = find(menus, function (m) {
        return m.id === parentPath[2];
      });
      setParentMenu(menu);
    }
  }, [menus, location.pathname]);

  useEffect(() => {
    const validMenus = aclService.allowedMenus();
    setMenus(validMenus);
  }, []);

  return (
    <Layout className="main-layout">
      <SiderLayoutComponent
        compact={compact}
        onCollapseChanges={setCollapseSider}
        menu={
          <MenuComponent
            onCompactChange={setCompact}
            isCollapse={collapseSider}
            pathName={location.pathname}
            menus={menus}
          />
        }
      />
      <Layout className="main-layout-content">
        <HeaderLayoutComponent
          menus={menus}
          parentMenu={parentMenu}
          isSubmenu={isSubMenu()}
          pathName={location.pathname}
        />
        {/* <Content className="main-content px-3 py-1"> */}
        <Content className="px-3 py-1">
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};
