import { RequestService } from '@clodeo/libs/core/rest/request.service';
declare interface IUserVariable  {
  key: string;
  value: any;
}
export class UserVariableRestService {
  requestService = new RequestService();
  request: RequestService;

  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl}/user-variables`, useInterceptor, withoutInterceptor);
  }

  setData(payload) {
    return this.request.post<IUserVariable>('', payload);
  }

  getData(params?) {
    return this.request.get<{errors: any[], success: boolean, data: IUserVariable[]}>(params);
  }
}
