// analytics tracker
// import 'autotrack/lib/plugins/event-tracker';
// import 'autotrack/lib/plugins/url-change-tracker';
// import $ from 'jquery';
import { get } from 'lodash';
import $ from "jquery";
declare let ga: Function;

export class GeneratorScriptService {
  isGenerated: boolean = false;
  isFbqGenerated: boolean = false;

  defaultExtension() {
    return {
      facebook_pixel: '',
      google_analytic: '',
      whatsapp_number: ''
    };
  }

  generateScript(
    { facebook_pixel_key, /* google_analytic */ },
    config?: { fb?: any, /* ga?: any */ }
  ) {
    if (this.isGenerated) {
      return;
    }

    const facebookPixelSc = `
      <!-- Facebook Pixel Code -->
      <script>
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${facebook_pixel_key}');
        fbq('track', 'PageView');
      </script>
      <noscript><img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=${facebook_pixel_key}&ev=PageView&noscript=1"
      /></noscript>
      <!-- End Facebook Pixel Code -->
      `;

    // const googleAnalyticSc = `
    //   <!-- Global site tag (gtag.js) - Google Analytics -->
    //   <script async src="https://www.googletagmanager.com/gtag/js?id=${google_analytic}"></script>
    //   <script>
    //     window.dataLayer = window.dataLayer || [];
    //     function gtag(){dataLayer.push(arguments);}
    //     gtag('js', new Date());
    //     gtag('config', '${google_analytic}');
    //   </script>
    //   <script>
    //   (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    //           (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    //       m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    //   })(window,document,'script','https://www.google-analytics.com/analytics_debug.js','ga');
    //   window.ga_debug = {trace: true};
    //   </script>

    //   `;

    // if (google_analytic) {
    //   ga('create', google_analytic, 'auto');

    //   // Only require the plugins you've imported above.
    //   ga('require', 'eventTracker');
    //   ga('require', 'urlChangeTracker');
    //   ga('send', 'pageview');
    //   $('head').append(googleAnalyticSc);
    // }

    if (facebook_pixel_key && config.fb.fbqStatus) {
      $('head').append(facebookPixelSc);

      this.isFbqGenerated = true;
    }
    this.isGenerated = true;
  }

  generateIntercomScript() {
    const script = `
     (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/zypj1yvn';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
    `

    var sc = document.createElement("script");
    sc.text = script;
    sc.setAttribute("type", "text/javascript");
    document.head.appendChild(sc);
  }
  shutdown() {
    (<any>window).Intercom('shutdown');
  }

  loadIntercom() {
    const w = <any>window;
    let intercom = w.Intercom;

    if (typeof intercom === 'function') {
      intercom('reattach_activator');
      intercom('update', w.intercomSettings);
    } else {
      this.generateIntercomScript();
    }
  }

  bootIntercom(userData: any, bootFromLogin = false) {
    // this.shutdown();
    this.loadIntercom();
    let user;
    if (bootFromLogin) {
      user = userData
    } else {
      const currentUser = localStorage.getItem('currentUser');
      try {
        userData = JSON.parse(currentUser);
        user = get(userData, 'user', '');
      } catch (e) { }
    }

    const userForIntercom = {
      app_id: 'zypj1yvn',
      user_id: user?.id || user?.userId,
      'User Name': user.fullName,
      name: user.fullName,
      phone: user.phone,
      email: user.email,
      company: {
        id: user?.userCompany?.companyId,
        name: user?.userCompany?.companyName,
        Phone: user?.userCompany?.companyPhone,
        Email: user?.userCompany?.companyEmail,
        company_id: user?.userCompany?.companyId,
        TenantID: user?.userCompany?.partnerId,
        created_at: user?.userCompany?.companyCreateDateUTC,
        'Company Address': user?.userCompany?.address?.fullAddress,
      },
      courierList: user?.courierList,
      avatar: {
        type: "avatar",
        image_url: user?.userCompany?.companyPicture
      }
    }
    if (userForIntercom) {
      (<any>window).Intercom('boot', Object.assign(userForIntercom));
    }
    return userForIntercom;
  }

}
